/* You can add global styles to this file, and also import other style files */
:root {
    --primary-color: #1D91FC;
    --primary-color-hover: #6DB4FF;
    --primary-text: #66626E;
    --primary-gradient: linear-gradient(93.71deg, #1D91FC 0%, #0075FF 100%);

    --secondary-gradient: linear-gradient(92.62deg, #FF8C67 0%, #FF602E 100%);
    --secondary-text: #271947;
    --secondary-color-bg: #EBF0F9;

    --footer-bg: #EBF2FA;
    --white: #FFFFFF;
    --white2: #F6F6FB;
    --light-gradient-contrast: linear-gradient(0deg, #FCFCFC, #FCFCFC);
    --white-gradient: linear-gradient(0deg, #FFFFFF, #D1E4FA);
    --white-gradient-fallback: #D1E4FA;

    --primary-button-text-color: var(--white);
    --primary-button-background: var(--primary-gradient);
    --primary-button-hover: #6DB4FF;
    
    --secondary-button-text-color: var(--primary-color);
    --secondary-button-background: var(--white);
    --secondary-button-hover: #6DB4FF;

    --accent-button-text-color: var(--primary-color);
    --accent-button-background: #DCEEFE;
    --accent-button-hover: #6DB4FF;

    --input-border: #ECEDF0;
    --input-border-hover: #EEEEEE;
    --input-background: #F3F6FC;
    --disabled-grey: #ECEDF0;

    --error-text: #F96060;
    --success-color: #36CC60;

    --tab-bar-background: #F4F6FA;
    --tab-bar-disabled-text: #8A8A8A;

    --scrollbar-color: #CCCCCC;
}

@font-face {
    font-family: 'Outfit';
    src: url('assets/fonts/outfit/Outfit-Regular.ttf');
}

body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

[hidden] {
    display: none !important;
}

h1 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 76px;
    color: var(--secondary-text);
}

h2 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    color: var(--secondary-text);
}

h3 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 160%;
    color: var(--secondary-text);
}

.body-16 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--primary-text);
}

.body-14 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--primary-text);
}

.body-12 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: var(--primary-text);
}

.body-10 {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    color: var(--primary-text);
}

.text {
    &--secondary {
        color: var(--secondary-text);
    }
}

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 40px;
    left: 27px;
    right: 26px;
    padding: 0px 12px;
    gap: 10px;
    border-radius: 10px;
    border: none;

    &:focus-visible {
        outline: none;
    }

    &--primary {
        color: var(--primary-button-text-color);
        background: var(--primary-button-background);
        box-shadow: 0px 2px 15px 0px rgba(29, 145, 252, 0.3);

        &:hover {
            background: var(--primary-button-hover);
            box-shadow: none;
            cursor: pointer;
        }
    }

    &--secondary {
        color: var(--secondary-button-text-color);
        background: var(--secondary-button-background);
        box-shadow: 0px 2px 15px rgba(29, 145, 252, 0.3);
        
        &:hover {
            background: var(--white);
            color: var(--secondary-button-hover);
            box-shadow: none;
            cursor: pointer;
        }
    }

    &--secondary_accent {
        background: var(--white);
        border: 1px solid var(--accent-button-hover);
        color: var(--accent-button-hover);
        
        &:hover {
            color: var(--accent-button-text-color);
            background: var(--accent-button-background);
            box-shadow: none;
            border: none;
            cursor: pointer;
        }
    }

    &--accent {
        color: var(--accent-button-text-color);
        background: var(--accent-button-background);
        
        &:hover {
            background: var(--white);
            border: 1px solid var(--accent-button-hover);
            color: var(--accent-button-hover);
            box-shadow: none;
            cursor: pointer;
        }
    }

    span {
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
    }

    fa-icon {
        font-size: 15px;
    }
}

.round-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: var(--white);
    box-shadow: 0px 2px 15px 0px rgba(29, 145, 252, 0.20);
}

.divider {
    display: flex;
    align-self: center;
    width: 100%;
    height: 1px;
    background-color: var(--disabled-grey);

    &--p30 {
        width: calc(100% - 60px); // 30px on both sides, since we align ourselves in the center
    }
}

.tab-bar {
    &--active {
        color: var(--white);
        background-color: var(--primary-color);

        &:hover {
            color: var(--white);
            background-color: var(--primary-color);
        }
    }

    &--disabled {
        color: var(--tab-bar-disabled-text);
        background-color: transparent;

        &:hover {
            color: var(--white);
            background-color: var(--primary-color);
        }
    }
}

.w {
    &--100 {
        width: 100%;

        &_important {
            width: 100% !important;
        }
    }
}

app-gradient-overlay {
    display: flex;
    position: absolute;
    justify-content: center;
    width: 100%;
    height: 100%;
}

app-roadmap-circles-overlay {
    width: 400px;
    height: 300px;
    position: absolute;
    overflow: hidden;

    @media only screen and (max-width: 419px) {
        width: 95vw;
    }
}